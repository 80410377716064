body.explore-mode
  header
    background transparent
    color $off-black
    justify-content flex-start
    pointer-events none
    nav
      display none
    h2
      flex 0 1 auto
    .explore-btn.back
      pointer-events auto
  #storyline,
  article.explore,
  footer.section
    visibility hidden
  .map-control.map-pan
    background explore-panel-border
    cursor default

footer.explore
  position fixed
  bottom 0
  width 100%
  padding 0
  color explore-button-text
  display flex
  justify-content space-between
  background explore-panel-background
  transition bottom .5s
  .hide
    display inline
  .show
    display none
  &.down
    .hide
      display none
    .show
      display inline
    bottom -11.6rem
  .explore-controls
    padding .7rem
    margin auto 0
    &.explore-layers
      border-right 2px solid explore-panel-border
      border-left 2px solid explore-panel-border
      width 100%
      padding-bottom 0

.explore-btn
  color explore-button-text
  background explore-panel-background
  border-radius 4px
  padding .2rem .7rem
  cursor pointer
  font-size $regular-font
  border 1px solid explore-button-text
  text-align center
  user-select none
  &:not(:disabled):active
  &.active
    color tint(black, 20%)
  &.back
    display block
    margin .3rem 0 0 3rem
    border-radius 3px
    background white
    border 0
    box-shadow 0 1px lightgrey
    height 2rem
    &:active
      opacity: .85
  .play-icon
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-left: 12px solid #000;
    border-bottom: 8px solid transparent;
    transform: translate(1px, 3px)
  &.active .play-icon
    border none
    width: 4px;
    height: 16px;
    border-right: 4px solid #000;
    border-left: 4px solid #000;

.explore-btn-group
  .explore-btn
    margin -1px
    float left
    border-radius 0px
    &:first-of-type
      border-radius 1rem 0 0 1rem
    &:last-of-type
      border-radius 0 1rem 1rem 0

.navigation-line
  display flex
  justify-content space-between
  padding-bottom .35rem

  .layer-label
    flex 2 0 auto
    margin-rigth .5rem
  .explore-btn-group
  .explore-btn.visibility
    margin 0 1rem
    flex 1 0 auto
  .legend
    margin-left .5rem
    flex 0 1 auto

  &:last-child
    padding-top .35rem

  .layer-label
    text-transform uppercase
    font-size $small-font
    padding 0
    margin auto 0
    width 3rem

input:checked + .explore-btn
  color $off-black

input.last:checked + .explore-btn
  cursor default

input:disabled + .explore-btn
  cursor default
  background explore-panel-border

input:checked + .explore-btn,
input:not(:disabled) + .explore-btn:active
  &.community
    background community
  &.competitors
    background competitors
  &.potential-competitors
    background potential-competitors
  &.target-community
    background target-community

input:checked:disabled + .explore-btn
  &.community
    background tint(community, $disabled-tint)
  &.competitors
    background tint(competitors, $disabled-tint)
  &.potential-competitors
    background tint(potential-competitors, $disabled-tint)
  &.target-community
    background tint(target-community, $disabled-tint)
  color explore-button-text
  .left-i
    stroke explore-button-text

.legend
  text-transform uppercase
  border-radius 4px
  font-size $small-font
  width 100%
  min-width 6rem
  max-width 20rem
  font-weight 700
  color $off-white
  padding 0 .5rem
  background explore-panel-border
  &::before
    content 'from'
    vertical-align -70%
  &::after
    content 'about'
    text-align right
    float right
    padding-top .6rem
  &.competitors
    background linear-gradient(to right, community, competitors)
  &.community
    background linear-gradient(to right, community, competitors)
  &.potential-competitors
    background linear-gradient(to right, community, potential-competitors)
  &.target-community
    background linear-gradient(to right, target-community, competitors)

.control-container
  height 4rem
  .explore-btn
    border none
    background explore-panel-border
    &:active
    &.active
      &::after
        border-color transparent transparent transparent tint(black, 20%)
    width 4rem
    &.play
      height 4rem
  &.vertical
    display inline-flex
    flex-direction column
    justify-content space-between
    padding 0
  &.icons .explore-btn
    padding 0
    background explore-panel-background
    width 5rem
    height 4rem
    font-size $small-font
    text-transform uppercase
    &:disabled
      color tint(explore-button-text, 50%)
      .i
        stroke tint(explore-button-text, 50%)

.visibility
  padding 0 .7rem

.pull-tab
  display inline-block
  height 1rem
  position absolute
  width 6.5rem
  top -2rem
  right 1rem
  background explore-panel-background
  padding .5rem .7rem
  border-radius 4px 4px 0 0
  cursor pointer

.explore-btn:not(:disabled):active .i,
.explore-btn:not(:disabled):active .left-i,
.explore-btn.active .i,
.explore-btn.active .left-i,
input:checked + .explore-btn .i,
input:checked + .explore-btn .left-i
  stroke tint(black, 20%)

#timeline
  width 100%
  min-width 25rem
  height 4rem
  .x.axis path
    fill none
    stroke explore-button-text
  .tick
    line
      stroke explore-button-text
      stroke-width 2px
    text
      font-size $small-font
      fill explore-button-text
    &:last-of-type text
      display none
  .layer
    &.competitors
      fill competitors
    &.community
      fill community
    &.potential-competitors
      fill potential-competitors
    &.target-community
      fill target-community
    &.outline
      fill explore-panel-background
  .brush
    rect
      fill none
      stroke explore-button-text
      stroke-width 2
  .handle
    fill none
    stroke explore-button-text
    stroke-width 2
  .mask
    fill explore-panel-border

body:not(.explore-mode)
  header
    button.back
      display none
  footer.explore
    visibility hidden
    bottom -10rem

@media (min-width: ($big-screen - 1px))
  footer.explore
    flex-wrap nowrap
    &.down
      bottom -6.2rem
  .explore-playback
    order 1
    flex 1 0 auto
  .explore-layers
    flex 0 3 auto
    order 3
    padding-bottom 1rem
  .explore-misc
    flex 1 0 auto
    order 4
  .explore-timeline
    order 2
    flex 1 0 auto
  .navigation-line
    .layer-label
      margin-rigth .1rem
    .explore-btn-group
    .explore-btn.visibility
      margin 0 .2rem
    .legend
      margin-left .1rem
    &:first-child
      border-bottom 2px solid explore-panel-border
  #timeline
    width 25rem

@media (max-width: $big-screen)
  footer.explore
    flex-wrap wrap
  .explore-playback
    order 2
    flex 0 1 auto
  .explore-layers
    order 1
    flex 0 1 auto
    padding-bottom 1rem
  .explore-misc
    order 4
    flex 0 1 auto
  .explore-timeline
    order 3
    flex 0 1 63%
  .navigation-line
    border-bottom 2px solid explore-panel-border

.exhibit-mode-info
  bottom 0
  left 0
  z-index 100
  position fixed
  width 100%
  height 100%
  display flex
  align-items center
  &.hidden
    display none
  .exhibit-icons
    margin-left $storyline-margin + $section-margin - 1rem
  .info-icon
    flex 1 0 auto
    .i
      display inline-block
      fill $off-black
      stroke $off-black
      height 11rem
      width 11rem
    span
      font-size 2rem
