#storyline
  position relative
  width $storyline-width + (2 * $section-margin)
  z-index 8
  margin-left $storyline-margin
  &.locked
    pointer-events none

article
  margin-top 10rem
  &:first-child
  &.short
    margin-top 0rem
  .section
    padding $section-margin
    cursor pointer

.landing
  height 100vh
  vertical-align center
  display flex
  justify-content center
  flex-direction column
  .section
    width 38rem

article.active .section
  cursor default

article.active:not(.landing):not(.row) .section
  background #fffffe

article.locked
  position fixed
  width $storyline-width + 2 * $section-margin
  left $storyline-margin
  bottom $storyline-margin
  height auto
  pointer-events auto
  h1
  h2
  h3
  p
  .tweet
  .chart
    display none
  .section
    background #fffffe
    width $storyline-width

article.row
  width 100vw
  position relative

.column
  width $storyline-width
  display inline-block
  margin-right $section-margin
  vertical-align: top
  &:last-child
    margin 0
  .i
    display inline-block
    fill $off-black
    stroke $off-black
    height 8rem
    width 8rem

  .info-icon
    display inline-block
    transform: scale(0.9)
    span
      display block
      text-align center
      margin-left 1.5rem

.subtitle
  font-size 1.5rem
  line-height 2rem

.btn.explore
  align-items: center
  height: 44px
  margin 1rem 0 1rem 0
  padding 0 1.8rem
  .left-i
    width 1.8rem
    height 1.8rem
    margin-right: 8px
    fill none
    stroke $off-black
  span
    display: inline-block
    transform: translate(0, 1px)
