html
  -ms-text-size-adjust 100%
  -webkit-text-size-adjust 100%

body
  margin 0
  font 16px/1.5 'Roboto', Helvetica, Arial, sans-serif
  -moz-osx-font-smoothing grayscale
  -webkit-font-smoothing antialiased
  font-weight 400
  color $off-black

html
body
  max-width 100%
  overflow-x hidden

html, .no-select
  user-select none

h1, h2, h3, h4, p, ul, ol
  margin 0
  padding 0
  font-size inherit
  user-select user-select-mode

p, ol
  margin-bottom 1rem
  line-height 1.5rem
  li
    margin-bottom: 0.5rem

h1
  font-size 3.6rem
  font-weight 700

h2
  font-weight 300
  font-size 1.8rem
  margin-bottom: 1rem
  strong
    font-size 2.4rem

h3
  font-weight 300
  font-size 1.5rem
  margin-bottom: 0.5rem

ol
  margin-left: 1rem

ul
  list-style none

strong
  font-weight 700

a
  color inherit
  text-decoration none
  &:hover
    cursor pointer

a.is-underlined
  text-decoration underline

hr
  border 1px solid
  margin -1px 0

b, strong, em, small, code
  line-height 1

#svg-defs
  position absolute
  top 0
  left 0
  width 0
  height 0

/*Stats*/
#stats
  position fixed
  left 0px
  top 0px
  padding 8px
  z-index 32

#render-stats
	position: fixed
	left: 0px
	bottom: 0px
	z-index: 32

.tweet-group
  margin-top: 1.8rem

.tweet
  display: inline-block
  border-radius 0.2rem
  &:not(:first-child)
    margin-top 1rem
  .logo
    margin-right 1.2rem
    float left
    clear both
  p
    margin 0
    overflow auto
  .username
  .hashtag
    font-weight 700
  .link
    color: explore-button-text

.highlight
  font-weight 700

  &.music
   color music

  &.work
   color work

  &.internet
   color internet

  &.food
   color food

  &.entertainment
   color entertainment

  &.sports
   color sports

  &.events
    color events

#neighborhoods .highlight
  color neighbourhoods-charts

.i
  display block
  margin auto auto 1rem auto
  width 2rem
  height 2rem
  fill $off-black
  stroke explore-button-text

.left-i
  vertical-align middle
  width 2rem
  height 2rem
  fill none
  stroke explore-button-text

.i-lens
.i-csv
.i-photo
  fill none

*:focus
  outline 0

.noscroll
  overflow hidden

.invisible
  visibility hidden

.hidden
  display none

.no-margin
  margin-bottom: 0
