footer
  position relative
  width 100%
  height auto
  background #fff
  background rgba(255,255,255,0.8)
  z-index 8
  padding $storyline-margin ($storyline-margin + $section-margin)
  margin-top 4rem

  div
    display inline-block
    vertical-align top

  .credits
    width $storyline-width

  .logo
    display inline-block
    height 6rem
    background-repeat no-repeat
    background-size contain
    margin-top 1rem

  #habidatum-logo
    margin-top 3rem
    width 14rem
    background-image url('../images/logo_habidatum.svg')

  #mathrioshka-logo
    width 6rem
    margin-left 2rem
    background-image url('../images/logo_mathrioshka.svg')

  .about
    width 100% - (2 * $storyline-margin) - $storyline-width - (3 * $section-margin)
    margin-left $section-margin
