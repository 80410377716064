-btn($color)
  background none
  border 1px solid $color
  color $color
  &:active
    color $off-white
    background shade($color, 15%)
    border-color shade($color, 15%)

-btn-checked($color)
  color $off-white
  border 1px solid $color
  background $color
  &:active
    background shade($color, 15%)
    border-color shade($color, 15%)

.btn
  display inline-block
  margin 0 1rem 1.2rem 0
  font-family Roboto
  font-size $regular-font
  text-transform uppercase
  background none
  border-radius 4px
  padding .25rem 1.2rem
  text-align center
  border 1px solid explore-button-text
  color explore-button-text
  font-weight 400
  cursor pointer
  user-select none
  &.btn-wide
    width 100%

  &.positive
    -btn positive

  &.negative
    -btn negative

  &.music
    -btn music

  &.work
    -btn work

  &.internet
    -btn internet

  &.food
    -btn food

  &.entertainment
    -btn entertainment

  &.sports
    -btn sports

  &.events
    -btn events

  &.competitors
    -btn competitors

  &.community
    -btn community

  &.competitors-2
    -btn competitors

  &.target-communities
    -btn target-community

  &.all-competitors
  &.all-targets
    -btn explore-button-text

  // FIXME: Manual padding correction
  &.competitors-2
  &.target-communities
  &.all-targets
  &.community-talk
  &.competitors-talk
  &.all-competitors
    padding: 4px

  &.community-talk
    -btn community

  &.competitors-talk
    -btn potential-competitors

  &.explore
    -btn-checked explore
    color $off-black

  &.all-neighbourhoods
    -btn-checked neighbourhoods-charts

  &.continue
    -btn-checked explore-button-text
    margin-top 1.5rem

  &.got-it
    -btn-checked explore-button-text

input:checked + .btn
  outline 0
  cursor default
  background explore-button-text
  color $off-white

  &.positive
    -btn-checked positive

  &.negative
    -btn-checked negative

  &.music
    -btn-checked music

  &.work
    -btn-checked work

  &.internet
    -btn-checked internet

  &.food
    -btn-checked food

  &.entertainment
    -btn-checked entertainment

  &.sports
    -btn-checked sports

  &.events
    -btn-checked events

  &.competitors
    -btn-checked competitors

  &.community
    -btn-checked community

  &.competitors-2
    -btn-checked competitors

  &.target-communities
    -btn-checked target-community

  &.community-talk
    -btn-checked community

  &.competitors-talk
    -btn-checked potential-competitors

  &.all-competitors
  &.all-targets
    -btn-checked explore-button-text

input[data-toggle] + .btn
input[data-toggle] + .explore-btn
input[type="checkbox"]:checked + .btn
  cursor pointer

.btn-group
  display flex
  flex-wrap wrap
  margin-top: 1.5rem

  .btn
    flex 1 0 auto
    &.food
      width 20%
    &.btn-last
      margin-right 0
    &.btn-last-row
      margin-bottom 0
