.leaflet-control-attribution
  display: attribution-display-mode

#maps
#flat-map
#volumetric-map
  display block
  position fixed
  top 0
  left 0
  width 100%
  height 100%

#maps
  transition filter 0.6s ease-in-out, opacity 0.6s ease-in-out

#volumetric-map
  &.area-lens-hovered
    cursor all-scroll
  &.transition
    cursor wait

#flat-map
#volumetric-map
  visibility hidden
  cursor default
  &.manual-mode:not(.area-lens-hovered)
    cursor grab
    &:not(.area-lens-hovered):active
      cursor grabbing

#volumetric-map
  z-index 4

/*leaflet*/
.leaflet-container {
  background none
}

/*Instagram photo-frame*/
.leaflet-container .leaflet-instagram-frame
  width 64px
  height 64px
  overflow visible
  cursor pointer
  transform translate(-32px,-64px)

.leaflet-instagram-image
  clip-path url(#instagramClipper)
  transform scale(1)
  transform-origin 28px 64px
  transition all .25s

.leaflet-instagram-image:hover
  transform scale(2.5)

.leaflet-instagram-frame:hover
  z-index 32

/*Hatching for zone fill*/
pattern path {
  stroke-width 4
}

/*slower zoom animation*/
.leaflet-zoom-anim .leaflet-zoom-animated
  transition transform .5s cubic-bezier(0,0,0.25,1)

@keyframes mapOverlayEnters {
  0% {
    opacity 0
  }
  100% {
    opacity 1
  }
}

.leaflet-image-layer,
.leaflet-heatmap-layer,
.leaflet-marker-icon,
.leaflet-instagram-frame
  animation-name mapOverlayEnters
  animation-duration .5s
  animation-timing-function cubic-bezier(0,0,0.25,1)

/*markers*/
.leaflet-marker-icon
  cursor inherit

/*hood popups*/
.hood-popup
  font 1rem 'Roboto', Helvetica, Arial, sans-serif
  font-weight 300
  border-top-color #fff
  cursor pointer
  &:hover
    border-top-color #0096be
    z-index 32
  &:active
    border-top-color shade(#0096be, 15%)

.hood-popup .leaflet-popup-content-wrapper
  border-radius 2px
  box-shadow 0px 2px 0px rgba(0, 0, 0, .2)
  transition all 0.25s

.hood-popup .leaflet-popup-content-wrapper:hover
  color #fff
  background #0096be

.hood-popup .leaflet-popup-content-wrapper:active
  color #fff
  background shade(#0096be, 15%)

.hood-popup .leaflet-popup-content
  padding 0.3rem 1rem !important

.hood-popup .leaflet-popup-close-button
  display none

.hood-popup .leaflet-popup-tip-container
  border-top-color inherit

.hood-popup .leaflet-popup-tip
  border-top-color inherit
  transition border-top-color 0.25s

.hood-popup .leaflet-popup-tip:after
  content ""
  position absolute
  left 1px
  top 0px
  width 0
  height 0
  border-top 0.7rem solid
  border-color rgba(0, 0, 0, .2)
  border-right 0.7rem solid transparent
  border-left 0.7rem solid transparent
  z-index -1;

.hood-popup p
  margin 0
  padding 0

.hood-label
  font 1rem 'Roboto', Helvetica, Arial, sans-serif
  font-weight 300
  background: $off-white
  border-radius: 2px
  padding: 8px
  box-shadow 0px 2px 0px rgba(0, 0, 0, .2)
  transition all 0.25s
  &.is-selected
    background explore-panel-border
    pointer-events none
  &:hover
    cursor: pointer
    color #fff
    background #0096be
  &:active
    background shade(#0096be, 15%)

.blurred
  filter blur(10px)
.opaque
  opacity 0.2

.blurred
.opaque
  pointer-events none

@keyframes mapControlsEnter {
  0% {
    transform: translateY(-50%)scale(1);
  }
  50% {
    transform: translateY(-50%)scale(1.2);
  }
  100% {
    transform: translateY(-50%)scale(1);
  }
}

.map-controls
  z-index 100
  position fixed
  top 50%
  transform: translateY(-50%);
  right 0
  margin-right $storyline-margin + $section-margin
  &.hidden
    display: none
  &.loud
    animation-name mapControlsEnter
    animation-duration 1.8s
    animation-delay 1.5s
    animation-timing-function cubic-bezier(0.645, 0.045, 0.355, 1)


.map-controls-group
  box-sizing border-box
  overflow hidden
  border-radius 4px
  margin-bottom .5rem
  box-shadow 0px 2px 0px rgba(0, 0, 0, .2)
.map-control
  color $off-black
  width 2rem
  height 2rem
  box-sizing border-box
  display block
  background $off-white
  border none
  font-weight 300
  font-size 1.5rem
  vertical-align middle
  text-align center
  cursor pointer
  line-height @height
  &:not(:last-child)
    border-bottom 1px solid explore-panel-border
  &:active
    background shade($off-white, 10%)
  &:disabled
    background explore-panel-border
  .i-locked
  .i-unlocked
    width 1.2rem
    height @width
    margin auto auto auto .25rem // HACK
  .i-unlocked
    display none
  &.locked
    .i-locked
      display none
    .i-unlocked
      display block
