.chart
  rect
    shape-rendering crispEdges
  height 18.6rem
  .value
    font-size .7rem
  .label
    font-weight 300
  svg
    path.domain
      fill none
  .positive
    fill positive
  .negative
    fill negative
  .from-chart, .about-chart
    fill neighbourhoods-charts
  .neighbourhood
    cursor pointer
    text
      font-weight 300
    rect
      fill: none
      stroke: #555
      shape-rendering: auto
    &.active
    &:active
      text
        font-weight 400
        fill: white
    &.active
      rect
        fill: neighbourhoods-charts
        stroke: neighbourhoods-charts
    &:active
      rect
        stroke: shade(neighbourhoods-charts, 15%)
        fill: shade(neighbourhoods-charts, 15%)

  text.active
    font-weight 700

.chart#emotions
  height: 14rem
