.tooltip
  display: flex
  position: absolute
  top: 96px
  right: 32px
  width: 290px
  background: $off-white
  transition: opacity .6s ease-in-out
  opacity: 0
  pointer-events: none
  padding: 24px 32px
  p
    margin-bottom: 12px

  &.is-visible
    opacity: 1
    pointer-events: auto

  &-left
    flex: 2
    min-height: 90px
    min-width: 90px
    margin-right: 20px

  &-right
    flex: 4
    .btn
      margin: 0
