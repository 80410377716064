header
  position fixed
  top 0
  left 0
  width 100%
  height 4rem
  background $off-black
  z-index 16
  color $off-white
  padding 0.75rem ($storyline-margin + 2rem) 0
  box-sizing border-box
  display flex
  h1
    font-size 1.8rem
    margin: 4px 8px 0 0
    font-weight 300
    width ($storyline-width + $section-margin)
    flex 0 0 auto
    .title-part
      font-weight 700
      transition opacity 0.5s
      &.invisible
        opacity 0
  nav
    flex 1 0 auto
    max-width 70rem
    ul
      position relative
      display flex
      justify-content space-between
    li
      flex 1 0 auto
      position relative
      font-size 1.25rem
      font-weight 300
      text-transform uppercase
      text-align center
      // HACK: firefox can’t align h1 by baseline
      padding-top .5rem
      a
        display block
        height 100%
        line-height 2.6rem
        box-sizing border-box
        padding 0 0.4em
        &.active
          font-weight 600
          pointer-events none
          border-bottom #fff solid 0.2rem
        &:hover
          border-bottom #fff solid 0.2rem

@media (min-width: $medium-screen)
  nav li
    letter-spacing .1rem
